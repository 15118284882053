import React from "react";
import { IoIosCheckmarkCircle, IoIosLink, IoMdWarning } from "react-icons/io";
import PropTypes from "prop-types";

/**
 *
 * @param {{ title: String, description: any, type: String, className: String  }} props
 * @returns
 */
const Banner = ({ title, description, type = "default", className = "", hideIcon, icon = "" }) => {
  return (
    <div
      className={` mt-4 mb-4 w-full flex items-center gap-4 p-5 rounded-md ${
        type === "success" ? "bg-green-100" : type === "warning" ? "bg-orange-100 " : "bg-cyan-100 "
      } ${className}`}
    >
      {!hideIcon && (
        <div className="icon-part">
          {type === "success" ? (
            <IoIosCheckmarkCircle color="green" size={40} />
          ) : type === "warning" ? (
            <IoMdWarning color="red" size={40} />
          ) : (
            icon
          )}
        </div>
      )}
      <div className="error-part w-full">
        <div>
          <b>{title}</b>
        </div>
        <div>{description}</div>
      </div>
    </div>
  );
};

Banner.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
  type: PropTypes.string,
  icon: PropTypes.any,
};

export default Banner;
