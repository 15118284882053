import axios from "axios";

import { LOGOUT_USER } from "../redux/ActionTypes";
// import { IS_COMPLETE_PROFILE } from "../redux/ActionTypes";
import store from "../redux/store";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const axiosMiddleware = () => {
  axios.interceptors.request.use(function (config) {
    const token = store.getState().auth?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.baseURL = BASE_URL;
    config.timeout = 30000;
    return config;
  });
  axios.interceptors.response.use((response) => response, manageErrorConnection);
};

function manageErrorConnection(err) {
  if (err?.response?.data?.error?.code === "INVALID_TOKEN") {
    store.dispatch({ type: LOGOUT_USER });
  }
  return Promise.reject(err);
}

export default axios;
