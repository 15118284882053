import {
  CREATE_SUBSCRIPTION,
  GET_PLAN,
  PAYMENT_LOADING,
} from "../ActionTypes";

const intialState = {
  plans: {},
  subscription: {},
  loader: true,
  btnloader: false,
};
export const PlanReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_PLAN:
      return {
        ...state,
        plans: action.payload.plan,
        loader: false,
      };
    case CREATE_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
        btnloader: false,
      };
    case PAYMENT_LOADING:
      return {
        ...state,
        btnloader: true,
      };
    default:
      return state;
  }
};
