import {
  CANCEL_POST_MODAL,
  CLEAR_POST_DETAIL,
  CREATE_POST,
  GET_CATEGORY,
  GET_DRAFT_POST,
  GET_LANDING_POST,
  GET_POST,
  GET_SIMILAR_POST,
  GET_USER_POST,
  HAS_MORE_POST,
  POST_DETAILS,
  POST_FILTER,
  POST_LOADING,
  POST_LOADING_CANCEL,
  PREVIEW_POST,
  RESET_POST,
  SEARCH_POST,
  UPDATE_POST,
} from "../ActionTypes";

const intialState = {
  publicPosts: {
    posts: [],
    totalPost: null,
  },
  myPosts: {
    posts: [],
    totalPost: null,
  },
  draftPosts: [],
  Createdpost: null,
  landingPost: [],
  similarPost: [],
  category: [],
  successmodal: false,
  loading: false,
  postDetails: null,
  hasMorePost: false,
  newPost: null,
  searchPosts: {
    posts: [],
    totalPost: null,
  },
};
export const PostReducer = (state = intialState, action) => {
  switch (action.type) {
    case HAS_MORE_POST: {
      return {
        ...state,
        hasMorePost: action.payload,
      };
    }
    case POST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case POST_LOADING_CANCEL:
      return {
        ...state,
        loading: false,
      };
    case GET_POST:
      return {
        ...state,
        publicPosts: action.payload,
        loading: false,
      };
    case SEARCH_POST:
      return {
        ...state,
        searchPosts: action.payload,
        loading: false,
      };
    case RESET_POST:
      return {
        ...state,
        // posts: action.payload,
        posts: [],
        loading: false,
      };
    case GET_USER_POST:
      return {
        ...state,
        myPosts: action.payload,
        loading: false,
      };
    case GET_DRAFT_POST:
      return {
        ...state,
        draftPosts: action.payload,
        loading: false,
      };
    case POST_FILTER:
      return {
        ...state,
        posts: action.payload,
        loading: false,
      };
    case CREATE_POST:
      return {
        ...state,
        myPosts: {
          posts: [...state.myPosts.posts, action.payload],
          totalPost: !state.myPosts.totalPost ? 1 : state.myPosts.totalPost + 1,
        },
        successmodal: action.showModal ? true : false,
        newPost: action.payload,
      };
    // case DELETE_POST: {
    //   const posts = state.myPosts.posts.filter((post) => post._id !== action.payload.id);
    //   return {
    //     ...state,
    //     myPosts: {
    //       ...state.myPosts,
    //       posts: [...state.myPosts.posts, posts],
    //     },
    //   };
    // }
    case UPDATE_POST:
      return {
        ...state,
        // posts: action.payload,
      };
    case CANCEL_POST_MODAL:
      return {
        ...state,
        successmodal: false,
        Createdpost: null,
        newPost: null,
      };
    case PREVIEW_POST:
      return {
        ...state,
        Createdpost: action.payload,
      };
    case GET_LANDING_POST:
      return {
        ...state,
        landingPost: action.payload,
        loading: false,
      };
    case GET_SIMILAR_POST:
      return {
        ...state,
        similarPost: action.payload,
        // data: true,
        loading: false,
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: action.payload,
        //loading: false,
      };
    case POST_DETAILS:
      return {
        ...state,
        postDetails: action.payload,
        loading: false,
      };
    case CLEAR_POST_DETAIL:
      return {
        ...state,
        // posts: action.payload,
        postDetails: null,
        loading: false,
      };
    default:
      return state;
  }
};
