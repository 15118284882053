import {
  GET_COWORKER,
  GET_INTEREST,
  GET_UEN_TOKEN,
  IS_COMPLETE_PROFILE,
  PROFILE_UPDATE,
  RESET_COMPANY,
  SET_USER_SIGNUP_TOKEN,
  SINGPASS_ACCESS_TOKEN,
  UPDATE_TAZAPAY_STATUSES,
  UPDATE_USER_TOKEN,
  USER_ALREADY_SUCCESSFULLY_REGISTERED,
  USER_COMPANY,
  USER_FINAL_FORM,
  USER_FORGET_PASSWORD,
  USER_LOADING,
  USER_LOADING_CANCEL,
  USER_LOGIN,
  USER_RESET,
  USER_RESET_PASSWORD,
  USER_SIGNUP,
  USER_STEP_INCREASE,
  USER_TYPE_FORM_TOUCHED,
  USER_UPDATE,
  USER_VERIFICATION,
} from "../ActionTypes";

const intialState = {
  token: null,
  user: null,
  subscription: null,
  uenToken: null,
  uenLoading: false,
  isCompleteProfile: false,
  userinfo: [],
  username: "",
  userId: "",
  userCompany: "",
  userEmail: "",
  ForgetpasswordSuccess: false,
  sigupSuccess: false,
  signUpinfo: {},
  company: null,
  usertypeformtouched: false,
  stepsize: 0,
  isuserverified: false,
  movetofinalform: false,
  furthercalltouserverify: true,
  movedTologin: false,
  loading: false,
  interest: [],
  coworkers: [],
  tazapay: {},
  existingUser: false,
  singpassToken: null,
  signupToken: null,
};
export const AuthReducer = (state = intialState, action) => {
  switch (action.type) {
    case IS_COMPLETE_PROFILE: {
      return {
        ...state,
        isCompleteProfile: action.payload,
      };
    }
    case SINGPASS_ACCESS_TOKEN: {
      return {
        ...state,
        singpassToken: action.payload,
      };
    }
    case SET_USER_SIGNUP_TOKEN: {
      return {
        ...state,
        signupToken: action.payload,
      };
    }
    case USER_LOGIN:
      return {
        ...state,
        user: action.payload.data.user,
        token: action.payload.token,
        subscription: action.payload.data.subcription,
        loading: false,
      };
    case UPDATE_USER_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case USER_SIGNUP:
      return {
        ...state,
        sigupSuccess: true,
        stepsize: state.stepsize + 1,
        signUpinfo: action.payload.data,
        loading: false,
      };

    case USER_ALREADY_SUCCESSFULLY_REGISTERED:
      return {
        ...state,
        sigupSuccess: true,
        stepsize: state.stepsize,
        existingUser: true,
        loading: false,
      };

    case USER_FORGET_PASSWORD:
      return {
        ...state,
        ForgetpasswordSuccess: true,
        loading: false,
      };
    case USER_RESET_PASSWORD:
      return {
        ...state,
        loading: false,
      };
    case USER_VERIFICATION:
      return {
        ...state,
        isuserverified: true,
        // stepsize:state.stepsize+1,
      };
    case USER_COMPANY:
      return {
        ...state,
        company: action?.payload,
      };
    case RESET_COMPANY:
      return {
        ...state,
        company: null,
      };
    case USER_UPDATE:
      return {
        ...state,
        movetofinalform: true,
        stepsize: state.stepsize + 1,
      };
    case USER_TYPE_FORM_TOUCHED:
      return {
        ...state,
        usertypeformtouched: true,
      };
    case USER_FINAL_FORM:
      return {
        ...state,
        // movetofinalform: true,
        // stepsize: state.stepsize + 1,
        // usertypeformtouched:false,
        // isuserverified: true,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USER_LOADING_CANCEL:
      return {
        ...state,
        loading: false,
      };
    case GET_INTEREST:
      return {
        ...state,
        interest: action.payload.interest,
      };
    case PROFILE_UPDATE: {
      state = {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
      return state;
    }
    case USER_RESET:
      return {
        ...state,
        stepsize: 0,
      };
    case USER_STEP_INCREASE:
      return {
        ...state,
        stepsize: state.stepsize + 1,
      };
    case GET_UEN_TOKEN: {
      return {
        ...state,
        uenToken: action.payload,
        uenLoading: action.loading,
      };
    }
    case GET_COWORKER: {
      return {
        ...state,
        coworkers: action.payload,
      };
    }
    case UPDATE_TAZAPAY_STATUSES: {
      return {
        ...state,
        tazapay: action.payload,
      };
    }
    default:
      return state;
  }
};
