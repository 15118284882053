import React from "react";

import { SearchCardWrapper } from "./SearchCardcompstyle";

function SearchCardComp(props) {
  let cardFromDate = props?.data?.availability?.from;
  let cardToDate = props?.data?.availability?.to;

  // console.log(props)

  return (
    <SearchCardWrapper>
      <div className="container hover:scale-[1.019] ease-in duration-200 bg-white rounded-2xl">
        <div className="picture">
          <img
            className=""
            style={{ objectFit: "contain" }}
            src={props?.data?.postImg[0] ? props?.data?.postImg[0] : "/images/LogoRed.png"}
            alt="post"
            onError={(e) => (e.target.src = "/images/LogoRed.png")}
          />

          {/* <div className="post-badge post -badge-left">
            {props?.data?.condition === "New" && <span className="post-badge-left-item"> New Item</span>}
          </div> */}

          {/* <div className="post-badge post-badge-right">
            <span
              className={`post-badge-right-item ${
                props.data.category === "sale" ? "post-badge-right-red" : "post-badge-right-blue"
              }`}
            >
              {props.data.category}
            </span>
            {props?.data?.status === "expired" && <span className="post-badge-right-item">Expired</span>}
            {props?.data?.status === "sold" && <span className="post-badge-right-item">Sold</span>}
            {props?.data?.status === "inactive" && <span className="post-badge-right-item">Inactive</span>}
          </div> */}

          {/* {props?.data?.condition === "New"
            ? props?.data?.isDraft === true && <div className="clip-draft">Draft</div>
            : props?.data?.isDraft === true && <div className="clip-drafts">Draft</div>} */}
        </div>
        <div className="details align-middle ">
          <div className="title">{props?.data?.postTitle || "******"}</div>
          {/* <div className="flex justify-between">
            <div className="subtitlewrapper sm:flex-col">
              {!props?.data?.user?.companyName ?? (
                <div className="subtitle">{props?.data?.user?.companyName || "sub"}</div>
              )}
              {(props?.data?.user?.companyName && props?.data?.user?.name) ?? <br></br>}
            </div>
            <div className="subtitlewrapper sm:flex-col">
              {!props?.data?.user?.name ?? <div className="subtitle">{props?.data?.user?.name || "sub"}</div>}
              <div className="star">
                <img src="/icons/star.svg" alt="" />
                <span className="star-text">{props?.data?.user?.avgRating || "N/A"}</span>
              </div>
            </div>
          </div> */}
          <div className="subtitlewrapper sm:flex-col">
            {props?.data?.user?.companyName ?? (
              <div className="subtitle">{props?.data?.user?.companyName || "sub"}</div>
            )}
          </div>

          {props?.data?.category !== "proServices" && (
            <div>
              <div className="pricewrapper">
                <div className="price">Unit Price</div>
                <div className="price-value">SGD {Number(props?.data?.pricing?.unitPrice).toFixed(2) || "****"}</div>
              </div>
              {/* <div className="quantitywrapper">
                <div className="quantity">Quantity</div>
                <div className="quantity-value">{props?.data?.pricing?.inStock || 0}</div>
              </div> */}
            </div>
          )}

          {/* {props?.data?.category === "lease" && (
            <div className="availabilitywrapper mb-2  ">
              <div className="availability flex-row flex">
                {" "}
                Avai<span className="sm:hidden  md:block">lability</span>
              </div>
              <div className="availability-value flex-row flex pt-1">
                <span className="sm:hidden  md:block">
                  {`${new Date(cardFromDate).getDate()}/${new Date(cardFromDate).getMonth() + 1}/${new Date(
                    cardFromDate
                  ).getFullYear()}`}{" "}
                </span>
                <span className="sm:block md:hidden">
                  {`${new Date(cardFromDate).getDate()}/${new Date(cardFromDate).getMonth() + 1}/${new Date(
                    cardFromDate
                  )
                    .getFullYear()
                    .toString()
                    .substring(2, 4)}`}{" "}
                </span>
                <span>&nbsp;</span>to<span>&nbsp;</span>
                <span className="sm:hidden  md:block">
                  {`${new Date(cardToDate).getDate()}/${new Date(cardToDate).getMonth() + 1}/${new Date(
                    cardToDate
                  ).getFullYear()}`}{" "}
                </span>
                <span className="sm:block  md:hidden">
                  {`${new Date(cardToDate).getDate()}/${new Date(cardToDate).getMonth() + 1}/${new Date(cardToDate)
                    .getFullYear()
                    .toString()
                    .substring(2, 4)}`}{" "}
                </span>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </SearchCardWrapper>
  );
}

export default React.memo(SearchCardComp);
