import styled from "styled-components";

export const ModalCardWrapper = styled.div`
  .id-text {
    color: red;
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 667px) {
    .ReactModal__Content {
      /* width: 60vw; */
    }
  }
`;
