import { toast } from "react-toastify";
import axios from "axios";

import { CANCEL_PAYMENT_LOADING, CREATE_PAYMENT, ORDER_PAYMENT_STATUS, PAYMENT_LOADING } from "../ActionTypes";

export const createPayment = (data, callback) => (dispatch) => {
  dispatch({ type: PAYMENT_LOADING });
  dispatch({
    type: CREATE_PAYMENT,
    payload: null,
  });
  dispatch({
    type: ORDER_PAYMENT_STATUS,
    payload: null,
  });

  return axios
    .post(`/payment/createPayment`, data)
    .then((resp) => {
      dispatch({
        type: CREATE_PAYMENT,
        payload: resp.data.body.data.redirect_url,
      });
      dispatch({ type: CANCEL_PAYMENT_LOADING });

      openLink(resp.data.body.data.redirect_url, (success) => callback(success));
    })
    .catch((err) => {
      console.log(err.message);
      dispatch({ type: CANCEL_PAYMENT_LOADING });
      toast.dark(err.response?.data?.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
};

export const openLink = (url, callback) => {
  try {
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = url;
    //a.target = "_blank";
    a.click();
    document.body.removeChild(a);
    callback && callback(true);
  } catch (error) {
    console.log(error);
    callback && callback(null);
  }
};
