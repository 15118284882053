import styled from "styled-components/macro";

export const FooterWrapper = styled.div`
  .main-container {
    background: whitesmoke;
    // padding: 4rem;
    border-top: 1px solid grey;

    // container start ---------------------------------------
    .container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 5rem;
    }
    //   container ends ------------------------------------

    //logo wrapper start -----------------------------------
    .logo-wrapper {
      .logo {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
          letter-spacing: 0.1rem;
          margin-top: 0.8rem;
        }

        img {
          width: 3rem;
        }
      }

      &-paragraph {
        margin-top: 2rem;
        font-size: 0.9rem;
        color: gray;
      }
    }

    //logo wrapper ends ---------------------------------------

    //about  starts--------------------------------------------

    .about {
      justify-self: center;
      margin-top: 1rem;

      &-title {
        font-weight: bold;
      }

      &-links {
        ul {
          list-style-type: none;
          display: initial;

          li {
            margin-top: 1.5rem;
            color: grey;
            cursor: pointer;
            font-weight: 500;

            a {
              text-decoration: none;

              &:link,
              &:visited,
              &:active {
                color: grey;
              }
            }
          }
        }
      }
    }

    // about ends ----------------------------------------------

    //learnmore starts-------------------------------------------

    .learnmore {
      justify-self: center;
      // margin-top: 1rem;

      &-title {
        font-weight: bold;
      }

      &-links {
        ul {
          list-style-type: none;
          /* display: initial; */

          li {
            /* margin-top: 0.5rem; */
            color: grey;
            cursor: pointer;
            /* font-weight: 500; */

            a {
              text-decoration: none;

              &:link,
              &:visited,
              &:active {
                color: grey;
              }
            }
          }
        }
      }
    }

    // learnmore ends--------------------------------------------

    // connect starts--------------------------------------------
    .connect {
      // justify-self: center;
      // margin-top: 1rem;

      &-title {
        font-weight: bold;
      }

      &-share {
        // margin-top: 1rem;
        img {
          border-radius: 0.5rem;
        }
        border-radius: 0.5rem;
        img:not(:first-child) {
          margin-left: 1rem;
        }
      }

      &-links {
        ul {
          // list-style-type: none;
          // display: initial;

          li {
            // margin-top: 0.5rem;
            // color: grey;
            // cursor: pointer;
            // font-weight: 500;

            a {
              text-decoration: none;

              &:link,
              &:visited,
              &:active {
                color: grey;
              }
            }
          }
        }
      }
    }
    // connect ends----------------------------------------------

    // footnote starts ------------------------------------------
    .footnote {
      // display: grid;
      // grid-template-columns: 4fr 1fr;
      /* margin-top: 4rem; */
      color: gray;
      font-size: 0.8rem;
      .copy {
        // display: flex;
        // align-items: center;
      }

      .payment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 1.7rem;
      }
    }
    // footnote ends -------------------------------------------
  }

  @media only screen and (max-width: 667px) {
    .footer-center {
      display: none;
     }
     .footnote {
      display: none;
     }
  }

`;
