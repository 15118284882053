import { toast } from "react-toastify";
import axios from "axios";

import { history } from "../../routes";
import {
  CANCEL_POST_MODAL,
  CLEAR_POST_DETAIL,
  CREATE_POST,
  DELETE_POST,
  GET_CATEGORY,
  GET_DRAFT_POST,
  GET_LANDING_POST,
  GET_POST,
  GET_SIMILAR_POST,
  GET_USER_POST,
  HAS_MORE_POST,
  POST_DETAILS,
  POST_FILTER,
  POST_LOADING,
  POST_LOADING_CANCEL,
  PREVIEW_POST,
  RESET_POST,
  SEARCH_POST
} from "../ActionTypes";

import { checkSellerStatus } from "./AuthActions";

export const getAllPosts = (params) => async (dispatch) => {
  dispatch({ type: POST_LOADING });
  dispatch({ type: HAS_MORE_POST, payload: true });

  try {
    const resp = await axios.get(`/post/getAllPosts`, { params });
    if (resp.data.data.posts.length === 0) {
      dispatch({ type: HAS_MORE_POST, payload: false });
    }
    dispatch({
      type: GET_POST,
      payload: resp.data.data,
    });
  } catch (error) {
    dispatch({ type: HAS_MORE_POST, payload: false });
  }
  dispatch({ type: POST_LOADING_CANCEL });
};

export const getSearchPosts = (params) => async (dispatch) => {
  dispatch({ type: POST_LOADING });
  dispatch({ type: HAS_MORE_POST, payload: true });

  try {
    const resp = await axios.get(`/post/getAllPosts`, { params });
    if (resp.data.data.posts.length === 0) {
      dispatch({ type: HAS_MORE_POST, payload: false });
    }
    dispatch({
      type: SEARCH_POST,
      payload: resp.data.data,
    });
  } catch (error) {
    dispatch({ type: HAS_MORE_POST, payload: false });
  }
  dispatch({ type: POST_LOADING_CANCEL });
};

export const getUserPost = (params) => (dispatch) => {
  dispatch({ type: POST_LOADING });
  return axios
    .get(`/post`, {
      params,
    })
    .then((resp) => {
      dispatch({
        type: GET_USER_POST,
        payload: resp.data.data,
      });
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getDraftPost = () => (dispatch) => {
  return axios
    .get(`/post/isDraft`)
    .then((resp) => {
      dispatch({
        type: GET_DRAFT_POST,
        payload: resp.data.data.posts,
      });
    })

    .catch((err) => {
      console.log(err.message);
    });
};

export const deletePost = (id) => async (dispatch) => {
  dispatch({
    type: POST_LOADING,
  });
  try {
    await axios.delete(`/post/${id}`);
    dispatch({
      type: DELETE_POST,
      payload: { id },
    });
    history.push("/mypost");
  } catch (error) {
    toast.dark(error?.response?.data?.message || error.message, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    console.log(error);
    return;
  }
};

export const createpost = (data) => (dispatch, getState) => {
  const tazapay = getState().auth.tazapay;
  dispatch({
    type: POST_LOADING,
  });
  return axios
    .post(`/post`, data)
    .then((resp) => {
      // history.push(`/mypost`)
      dispatch({
        type: CREATE_POST,
        payload: resp.data?.data?.post,
        showModal: data.isDraft ? false : true,
      });
      dispatch({
        type: POST_LOADING_CANCEL,
      });
      if (!tazapay.isSeller) {
        dispatch(checkSellerStatus());
      }
    })
    .catch((err) => {
      dispatch({
        type: POST_LOADING_CANCEL,
      });
      toast.dark(err?.response?.data?.message || err.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      console.log(err.message);
    });
};

export const updatePost = (pid, data, redirectTo) => (dispatch) => {
  dispatch({
    type: POST_LOADING,
  });
  return axios
    .patch(`/post/${pid}`, data)
    .then((resp) => {
      dispatch({
        type: POST_DETAILS,
        payload: resp.data?.data?.post,
      });
      toast.dark("Post updated successfully", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      dispatch({
        type: POST_LOADING_CANCEL,
      });
      redirectTo && history.push(redirectTo);
    })
    .catch((error) => {
      dispatch({
        type: POST_LOADING_CANCEL,
      });
      toast.error(error?.response?.data?.message || error.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
};

export const cancelPostModal = () => ({
  type: CANCEL_POST_MODAL,
  payload: "",
});

export const previewpost = (data) => ({
  type: PREVIEW_POST,
  payload: data,
});

export const getLandingPost = () => async (dispatch) => {
  return axios
    .get(`/post/popularPost`)
    .then((resp) => {
      dispatch({
        type: GET_LANDING_POST,
        payload: resp.data.data.popularPost,
      });
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const postFilter = (type, pageSize, page) => (dispatch) => {
  dispatch({
    type: POST_LOADING,
  });

  axios
    .get(`/post/getPostCategory/${type}?pageSize=${pageSize}&page=${page}`)
    .then((resp) => {
      dispatch({
        type: POST_FILTER,
        payload: resp.data.data.posts,
      });
    })
    .catch(() => {
      dispatch({
        type: POST_LOADING_CANCEL,
      });
    });
};

export const getSimilarPost = (data) => (dispatch) => {
  return axios
    .post(`/post/similarPost`, data)
    .then((resp) => {
      // console.log(resp);
      dispatch({
        type: GET_SIMILAR_POST,
        payload: resp.data.data.similarPost,
      });
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getCategory = () => (dispatch) => {
  return axios
    .get(`/category/getAllCategory`)
    .then((resp) => {
      dispatch({
        type: GET_CATEGORY,
        payload: resp.data.data.category,
      });
    })
    .catch((err) => {
      console.log(err.message);
    });
};

export const getpostdetail = (id) => (dispatch) => {
  dispatch(resetPostDetail());
  dispatch({ type: POST_LOADING });
  return axios
    .get(`/post/getSinglePosts/${id}`)
    .then((resp) => {
      // console.log(resp, "post detail action");
      dispatch({
        type: POST_DETAILS,
        payload: resp.data?.data?.singlePostDetail,
      });
      // return resp.data
    })
    .catch((err) => {
      dispatch({ type: POST_LOADING_CANCEL });
      console.log(err.message);
      // toast.dark("Post is not available", {
      //   position: toast.POSITION.TOP_CENTER,
      // });
    });
};

export const resetPost = () => ({
  type: RESET_POST,
  payload: "",
});

export const resetPostDetail = () => ({
  type: CLEAR_POST_DETAIL,
  payload: "",
});
