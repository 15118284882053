import styled from "styled-components";

export const NotificationPageWrapper = styled.div`
  .notification {
    .notification-head {
      margin-bottom: 1rem;
      .title {
        font-size: 1.5rem;
        font-weight: 500;
      }
    }
    .notification-data {
      /* width: 60%; */
      .notification-box {
        display: flex;
        flex-direction: column;
        .notification-btns {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          margin-right: 4rem;
        }
        .notification-wrap {
          display: flex;
          align-items: center;
          //justify-content:space-between;
          gap: 0.9rem;
          padding: 1rem;
          .notification-dot {
            height: 0.5rem;
            width: 0.5rem;
            background: #00cc18;
            border-radius: 50%;
            flex-shrink: 0;
          }
          .notification-img {
            //display:flex;
            /* width: 4rem;
              height: 4rem; */
            margin-right: 1rem;
            border-radius: 100%;
            overflow: hidden;
            flex-shrink: 0;
            //margin: 1rem 0.75rem 1rem 0;
            // position: relative;
            // align-self: center;

            img {
              /* height: 100%;
                width: 100%; */
              /* border-radius: 50%; */
            }
          }
          .new-tag {
            background: #d50012;
            padding: 0.2rem 0.4rem;
            color: white;
            font-size: 0.7rem;
            border-radius: 0.2rem;
            //margin-left:1.5rem;
          }
          .notification-detail {
            width: 65%;
            color: gray;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  .footer-notification {
    margin-top: 7rem;
  }
`;
