import {
  ADD_NOTIFICATION,
  GET_NOTIFICATION,
  NOTIFICATION_DELETE,
  NOTIFICATION_LOADING,
  NOTIFICATION_MARK_ALL_SEEN,
  NOTIFICATION_MARK_SEEN,
} from "../ActionTypes";

const intialState = {
  notifications: [],
  total: 0,
  unseen: 0,
  seen: 0,
  loading: true,
  hasMore: true,
};
export const NotificationReducer = (state = intialState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      const exists = state.notifications.find((n) => n?._id === action?.payload?._id);
      if (exists) {
        return state;
      }
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
        total: state.total + 1,
        unseen: state.unseen + 1,
      };
    }
    case GET_NOTIFICATION: {
      const notifications =
        action.page > 1 ? [...state.notifications, ...action.payload.data] : action.payload.data;
      return {
        ...state,
        notifications,
        total: action.payload.total || 0,
        unseen: action.payload.unseen || 0,
        seen: action.payload.seen || 0,
        loading: false,
        hasMore: notifications.length === state.total ? false : true,
      };
    }
    case NOTIFICATION_DELETE: {
      const notifications = state.notifications;
      const index = notifications?.findIndex((n) => n._id === action.nid);
      const [removed] = state.notifications.splice(index, 1);
      if (removed.isSeen === false) state.unseen = state.unseen - 1;
      return {
        ...state,
        notifications: notifications,
      };
    }

    case NOTIFICATION_MARK_ALL_SEEN: {
      const restNotification = state.notifications?.map((notification) => {
        return {
          ...notification,
          isSeen: true,
        };
      });

      return {
        ...state,
        notifications: restNotification,
        unseen: 0,
        seen: state.total,
      };
    }

    case NOTIFICATION_MARK_SEEN: {
      const restNotification = state.notifications?.map((notification) => {
        if (notification._id === action.payload.nid) {
          return {
            ...notification,
            isSeen: true,
          };
        }
        return notification;
      });

      return {
        ...state,
        notifications: restNotification,
        unseen: state.unseen - 1,
        seen: state.seen + 1,
      };
    }

    case NOTIFICATION_LOADING: {
      if (action.hasMore) state.hasMore = action.hasMore;
      return {
        ...state,
        loading: action.payload,
      };
    }

    default:
      return state;
  }
};
