import styled, { css } from "styled-components";

export const LoaderCompWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${(props) =>
    props.height
      ? css`
          height: ${props.height};
        `
      : css``}

  .loader-text {
    top: 2.4rem;
    left: 1.3rem;
    translate: transform(-50%, -50%);
  }
`;
