import { CANCEL_PAYMENT_LOADING, CREATE_PAYMENT, ORDER_PAYMENT_STATUS, PAYMENT_LOADING } from "../ActionTypes";

const intialState = {
  //successorder: false,
  paymentUrl: null,
  loading: false,
  paymentStatus: null,
};
export const PaymentReducer = (state = intialState, action) => {
  switch (action.type) {
    case ORDER_PAYMENT_STATUS: {
      return {
        ...state,
        paymentStatus: action.payload,
      };
    }
    case CREATE_PAYMENT:
      return {
        ...state,
        paymentUrl: action.payload,
      };
    case PAYMENT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CANCEL_PAYMENT_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
