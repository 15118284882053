import {
  FOLLOWERS_LIST,
  FOLLOWERS_LOADING_END,
  FOLLOWERS_LOADING_START,
  FOLLOWING_ADD,
  FOLLOWING_DELETE,
  FOLLOWING_DISABLE_POST_NOITY,
  FOLLOWING_ENABLE_POST_NOTIFY,
  FOLLOWING_LIST,
  FOLLOWING_LOADING_END,
  FOLLOWING_LOADING_START,
} from "../ActionTypes";

const intialState = {
  entries: [],
  followersEntries: [],
  loading: false,
};
export const FollowingReducer = (state = intialState, action) => {
  switch (action.type) {
    case FOLLOWING_LOADING_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FOLLOWING_LOADING_END: {
      return {
        ...state,
        loading: false,
      };
    }
    case FOLLOWING_LIST: {
      return {
        ...state,
        entries: action.payload,
      };
    }
    case FOLLOWING_ADD: {
      const newList = state.entries.filter(
        (item) => item.follow.following_user_id._id !== action.payload.follow.following_user_id._id
      );
      return {
        ...state,
        entries: [...newList, action.payload],
      };
    }
    case FOLLOWING_DELETE: {
      if (action.shouldRemove) {
        const filtered = state.entries.filter((item) => item?.follow?.following_user_id?._id !== action.payload);
        return {
          ...state,
          entries: filtered,
        };
      } else {
        return state;
      }
    }

    case FOLLOWING_DISABLE_POST_NOITY: {
      // const { userId } = action.payload;
      // console.log(action.payload);
      const filtered = state.entries.map((item) => {
        if (item?.follow?.following_user_id?._id === action.payload) {
          return { ...item, follow: { ...item.follow, isPostNotify: false } };
        }
        return item;
      });
      return { ...state, entries: filtered };
    }
    case FOLLOWING_ENABLE_POST_NOTIFY: {
      // const { userId } = action.payload;
      // console.log(action.payload);
      const filtered = state.entries.map((item) => {
        if (item?.follow?.following_user_id?._id === action.payload) {
          return { ...item, follow: { ...item.follow, isPostNotify: true } };
        }
        return item;
      });
      return { ...state, entries: filtered };
    }

    case FOLLOWERS_LOADING_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FOLLOWERS_LOADING_END: {
      return {
        ...state,
        loading: false,
      };
    }
    case FOLLOWERS_LIST: {
      return {
        ...state,
        followersEntries: action.payload,
      };
    }

    default:
      return state;
  }
};
