import React from "react";
import Modal from "react-modal";

// import { defaultProps } from 'react-select/src/Select';
import { ModalCardWrapper } from "./Modalcompstyle";

const ModalComp = (props) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: "1rem",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFF",
      textAlign: "center",
      width: props.width,
      borderRadius: ".8rem",
      border: "none",
      minWidth: "24vw",
      overflow: "unset"
    },

    overlay: {
      backgroundColor: "rgba(0,0,0,.6)",
      zIndex: 10,
    },
  };
  return (
    <ModalCardWrapper>
      <Modal isOpen={props.modalVisible} onRequestClose={props.modal} ariaHideApp={false} style={customStyles}>
        {props.children}
      </Modal>
    </ModalCardWrapper>
  );
};

export default ModalComp;
