import React from "react";
import Loader from "react-loader-spinner";
import PropTypes from "prop-types";

import { LoaderCompWrapper } from "./Loadercompstyle";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const LoaderComp = ({ className, type, height, width, description, ...props }) => {
  return (
    <LoaderCompWrapper className={`h-screen ${className}`} {...props}>
      <Loader
        type={type || "Oval"}
        color="#DA0022"
        height={height || 100}
        width={width || 80}
        // timeout={3000}           //3 secs
      />
      {description && <div className="loader-text">{description}</div>}
    </LoaderCompWrapper>
  );
};

LoaderComp.propTypes = {
  height: PropTypes.any,
  title: PropTypes.string,
};

export default LoaderComp;
