import React, { useState } from "react";

import { Input, Textarea } from "./Inputstyle";

/**
 * @param {{
 *  type: string
 *  value: string
 *  rows: number
 * }} props
 * @returns
 */

function InputComp(props) {
  const [value, setValue] = useState(props.value);
  const handleonKeyDown = (e) => {
    if (e.keyCode === 8) {
      setValue(value?.substring(0, value.length - 1));
    }
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  let Component = Input;
  if (props.type === "textarea") {
    Component = Textarea;
  }

  return (
    <>
      <Component
        {...props}
        type={props.type || "text"}
        value={props.value}
        name={props.name}
        placeholder={props.placeholder}
        id={props.id}
        max={props.max}
        min={props.min}
        onKeyDown={props.onKeyDown || handleonKeyDown}
        onChange={props.onChange || handleChange}
        onKeyPress={props.onkeypress}
        onBlur={props.onBlur}
        onFocus={props.onFocus}
        className={props.className}
        disabled={props.disabled || ""}
        readOnly={props.readOnly || ""}
        accept={props.accept || ""}
        hidden={props.hidden || ""}
        autoFocus={props.autoFocus}
        onClick={props.onClick}
        pattern={props.pattern}
      />
      <div style={{ color: "red" }}>{props.errors || ""}</div>
    </>
  );
}

export default InputComp;
