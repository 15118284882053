import { toast } from "react-toastify";
import axios from "axios";

import {
  ADD_NOTIFICATION,
  GET_NOTIFICATION,
  NOTIFICATION_DELETE,
  NOTIFICATION_LOADING,
  NOTIFICATION_MARK_ALL_SEEN,
  NOTIFICATION_MARK_SEEN,
} from "../ActionTypes";

export const getNotification = (params) => async (dispatch, getState) => {
  const { notifications, total } = getState().notification;
  if (params.page > 1 && notifications.length === total) {
    dispatch({ type: NOTIFICATION_LOADING, hasMore: false });
    return;
  }
  dispatch({ type: NOTIFICATION_LOADING, payload: true });
  await axios
    .get(`/notification`, { params })
    .then((resp) => {
      // console.log(resp, "notifi");
      dispatch({
        type: GET_NOTIFICATION,
        payload: resp.data,
        page: params.page,
      });
      resp.data.length === 0 &&
        toast.dark("You not have Notification", {
          position: toast.POSITION.TOP_CENTER,
        });
    })
    .catch((err) => {
      console.log(err.message);
      dispatch({ type: NOTIFICATION_LOADING, payload: false, hasMore: false });
    });
  dispatch({ type: NOTIFICATION_LOADING, payload: false });
};

export const deleteNotification = (nid) => (dispatch) => {
  return axios
    .delete(`/notification/${nid}`)
    .then((resp) => {
      dispatch({
        type: NOTIFICATION_DELETE,
        payload: resp.data.data,
        nid,
      });
    })

    .catch((err) => {
      console.log(err.message);
    });
};

export const markSeenNotification = (nid) => (dispatch) => {
  return axios
    .post(`/notification/${nid}`, { isSeen: true })
    .then(() => {
      dispatch({
        type: NOTIFICATION_MARK_SEEN,
        payload: {
          nid,
        },
      });
      return Promise.resolve();
    })

    .catch((err) => {
      console.log(err.message);
    });
};

export const markAllNotificationAsSeen = () => (dispatch) => {
  return axios
    .patch(`/notification/`)
    .then(() => {
      dispatch({
        type: NOTIFICATION_MARK_ALL_SEEN,
      });
      return Promise.resolve();
    })

    .catch((err) => {
      console.log(err.message);
    });
};

export const addNotification = (data) => (dispatch) => {
  dispatch({
    type: ADD_NOTIFICATION,
    payload: data,
  });
};
