import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { AddressReducer } from "./reducers/AddressReducer ";
import { AuthReducer } from "./reducers/AuthReducer";
import { BuyerRequestReducer } from "./reducers/BuyerRequestReducer";
import { ChatReducer } from "./reducers/ChatReducer";
import { CockpitReducer } from "./reducers/CockpitReducer";
import { DashboardReducer } from "./reducers/DashboardReducer";
import { FollowingReducer } from "./reducers/FollowingReducer";
import { FormReducer } from "./reducers/FormReducer";
import { NotificationReducer } from "./reducers/NotificationReducer";
import { OrderReducer } from "./reducers/OrderReducer";
import { PaymentReducer } from "./reducers/PaymentReducer";
import { PerformanceReducer } from "./reducers/PerformanceReducer";
import { PlanReducer } from "./reducers/PlanReducer";
import { PostReducer } from "./reducers/PostReducer";
import { ReviewReducer } from "./reducers/ReviewReducer";
import { LOGOUT_USER } from "./ActionTypes";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
  blacklist: ["auth.loading", "auth.signUpinfo"],
};

const appReducer = combineReducers({
  auth: AuthReducer,
  post: PostReducer,
  form: FormReducer,
  dashboard: DashboardReducer,
  plan: PlanReducer,
  order: OrderReducer,
  cockpit: CockpitReducer,
  review: ReviewReducer,
  performance: PerformanceReducer,
  chat: ChatReducer,
  notification: NotificationReducer,
  payment: PaymentReducer,
  following: FollowingReducer,
  buyerRequest: BuyerRequestReducer,
  address: AddressReducer
});

const initialState = appReducer({}, {});

const rootReducer = (state, action) => {
  if (action.type === "persist/PERSIST" && !state?.auth?.token && !state?.auth?.signupToken) {
    state = initialState;
    localStorage.clear();
  }
  if (action.type === LOGOUT_USER) {
    state = initialState;
    localStorage.clear();
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
