import {
  COCKPIT_LOADING,
  COCKPIT_LOADING_CANCEL,
  GET_COWORKER_COCKPIT,
  GET_MY_COCKPIT,
  INVOICE_PDF,
  UPDATE_ORDER} from "../ActionTypes";

const intialState = {
  // My Orders
  cockpits: [],
  cockpitsTotal: null,
  completed: [],
  completedTotal: null,
  unsuccessful: [],
  unsuccessfulTotal: null,

  // Co Worker Orders
  /*
    cockpitsCoworker: [],
    cockpitsCoworkerTotal: null,
    completedCoworker: [],
    completedCoworkerTotal: null,
    unsuccessfulCoworker: [],
    unsuccessfulCoworkerTotal: null

    coworkers: {
      "USER_RANDOM_ID": {
        cockpits: {
          entries: [],
          total: 0
        }
      }
    }

  */
  coworkers: {},

  enroutemodal: false,
  loader: false,
  userLoading: [],
  filtered_id: {},
};

export const CockpitReducer = (state = intialState, action) => {
  switch (action.type) {
    case COCKPIT_LOADING: {
      if (action.userId) {
        return {
          ...state,
          loader: true,
          userLoading: [...state.userLoading, action.userId],
        };
      }
      return {
        ...state,
        loader: true,
      };
    }
    case COCKPIT_LOADING_CANCEL:
      return {
        ...state,
        loader: false,
        userLoading: state.userLoading.splice(state.userLoading.indexOf(action.userId), 1),
      };
    case GET_MY_COCKPIT:
      return {
        ...state,
        [action.status]: action.payload.entries,
        [`${action.status}Total`]: action.payload.count,
        loader: false,
      };

    case GET_COWORKER_COCKPIT:
      return {
        ...state,
        coworkers: {
          ...state.coworkers,
          [action.userId]: {
            ...state.coworkers[action.status],
            [action.status]: action.payload,
          },
        },
        // [`${action.status}Coworker`]: action.payload.entries,
        // [`${action.status}CoworkerTotal`]: action.payload.count,
        loader: false,
        userLoading: state.userLoading.splice(state.userLoading.indexOf(action.userId), 1),
      };

    case UPDATE_ORDER:
      return {
        ...state,
        cockpits: state.cockpits.map((cockpit) => {
          if (cockpit?.orderId === action.payload.order._id) {
            return Object.assign({}, cockpit, {
              status: action.payload.order.status,
            });
          }
          return cockpit;
        }),
        loader: false,
      };
    case INVOICE_PDF:
      return {
        ...state,
      };
    default:
      return state;
  }
};
