import {
  CANCEL_LOADING,
  CLEAR_SEARCH,
  DASHBOARD_SEARCH,
  GET_ACTIVE_PUSH_INFO,
  GET_POPULAR_POST,
  LANDING_SEARCH,
  SET_LOADING,
} from "../ActionTypes";

const intialState = {
  popularPosts: [],
  searchData: [],
  landingSearchData: [],
  featurePosts: [],
  allCount: [],
  loading: true,
  loader: false,
  push_info: {
    count: 0,
    entries: [],
  },
};
export const DashboardReducer = (state = intialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loader: true,
      };

    case GET_ACTIVE_PUSH_INFO: {
      return {
        ...state,
        push_info: action.payload,
      };
    }

    case CANCEL_LOADING:
      return {
        ...state,
        loader: false,
      };
    case GET_POPULAR_POST:
      return {
        ...state,
        popularPosts: action.payload.popularPost,
        feature_posts: action.payload.featurePost,
        allCount: action.payload.allCount,
        bannerInfo:action.payload.bannerInfo,
        loading: false,
      };

    case DASHBOARD_SEARCH:
      return {
        ...state,
        searchData: action.payload.allPost,
        loader: false,
      };
    case CLEAR_SEARCH:
      return {
        ...state,
        searchData: [],
      };
    case LANDING_SEARCH:
      return {
        ...state,
        landingSearchData: action.payload.allPost,
        landing: false,
        //data: true,
        // loader: false,
      };
    default:
      return state;
  }
};
