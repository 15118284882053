import io from "socket.io-client";

import { getActivePushInfo } from "../redux/actions/DashboardAction";

export const socket_ip = process.env.REACT_APP_SOCKET_URL;

let socket = configureSocket();

function configureSocket() {
  return io(socket_ip, {
    withCredentials: true,
    reconnectionDelayMax: 10000,
    reconnection: true,
    autoConnect: true,
  });
}

export const initSocket = (room) => {
  if (room) socket.emit("join", room);
};

export const subscribeNotification = (cb) => {
  if (!socket) socket = configureSocket();

  socket.on("NOTIFICATION", (data) => {
    console.log("NOTIFICATION", data);
    cb(data);
  });
};

export const subscribeToChat = (cb) => {
  if (!socket) socket = configureSocket();
  socket.removeListener("chat");
  socket.on("chat", (data) => {
    if (data.status === "success") {
      const chat = data.data.chats;
      console.log("SUBSCRIBED", chat);
      cb(chat);
    }
  });
};

export const sendNewMessage = (data) => {
  socket.emit("chat", data);
};

export const chatUnreadCount = (cb) => {
  if (!socket) socket = configureSocket();
  socket.on("chatStatus", (data) => { 
    cb(data);
  });
};

// HELP DESK

export const sendHelpDeskMessage = (data) => {
  if (!socket) socket = configureSocket();
  socket.emit("adminChat", data);
};

export const subscribeToHelpDesk = (cb) => {
  if (!socket) socket = configureSocket();
  socket.on("HELP_DESK", (data) => {
    console.log("HELP_DESK", data);
    if (data.status === "success") cb(data.data);
  });
};

export const subscribePushInfo = (cb) => {
  socket.on("PUSH_INFO", (data) => {
    cb(data);
  });
  // Intial callback
  cb();
};
