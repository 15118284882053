import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";

import { AuthLayout, PrivateLayout } from "../container";

export const history = createBrowserHistory();

/**
 *
 * - AuthRoute is for those page which are accessible if user are not logged in
 * - But if they logged in they should redirect to authenticated page
 *
 * @returns {ReactNode}
 */
export const AuthRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => !!state?.auth?.token);
  return isAuthenticated ? (
    <Redirect to={{ pathname: "/home", state: { from: location } }} />
  ) : (
    <Route
      {...rest}
      component={(props) => (
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

/**
 *
 * - PrivateRoute is for those page which are accessible if user are logged in
 * - But if they are not logged in they should redirect to login page
 * 
 * @returns {ReactNode}
 */
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const isAuthenticated = useSelector((state) => !!state?.auth?.token);

  return isAuthenticated ? (
    <PrivateLayout>
      <Route {...rest} component={(props) => <Component {...props} />} />
    </PrivateLayout>
  ) : (
    <Redirect to={{ pathname: "/login", state: { from: location } }} />
  );
};

export const GuestRoute = ({ component: Component, ...rest }) => {
    return <PrivateLayout>
      <Route {...rest} component={(props) => <Component {...props} />} />
    </PrivateLayout>
};
