/**
 *
 * @param {Date} endDate
 * @param {Date} startDate
 * @returns
 */

export const dateDiffInDays = (endDate, startDate) => {
  const diffInMs = new Date(endDate) - new Date(startDate);
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24) + 1;
  return diffInDays;
};

export const getFilenameFromURL = (url) => {
  return /[^/]*$/.exec(url);
};

export const formatDate = (date) => {
  return new Date(date).toLocaleString('en-SG', {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

export const formatPrice = (price = 0) => {
  return "SGD " + parseFloat(price ? price : 0).toFixed(2);
};

export const formatDateTime = (date) => {
  return new Date(date).toLocaleString('en-US', {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

export const formatTime = (date) => {
  return new Date(date).toLocaleString('en-US', {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};
