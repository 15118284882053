import {
  CANCEL_CREATE_REVIEW_MODAL,
  CREATE_REVIEW,
  GET_REVIEW,
  GET_REVIEW_DETAIL,
  REVIEW_LOADING,
  REVIEW_LOADING_CANCEL,
  REVIEW_PRINT,
} from "../ActionTypes";

const intialState = {
  reviews: {},
  successreview: false,
  reviewDetail: {},
  loader: false,
};
export const ReviewReducer = (state = intialState, action) => {
  switch (action.type) {
    case REVIEW_LOADING:
      return {
        ...state,
        loader: true,
      };
    case REVIEW_LOADING_CANCEL:
      return {
        ...state,
        loader: false,
      };
    case CREATE_REVIEW:
      return {
        ...state,
        successreview: true,
        loader: false,
      };
    case CANCEL_CREATE_REVIEW_MODAL:
      return {
        ...state,
        successreview: false,
      };
    case GET_REVIEW:
      return {
        ...state,
        reviews: action.payload,
        loader: false,
      };
    case GET_REVIEW_DETAIL:
      return {
        ...state,
        reviewDetail: action.payload.review,
        loader: false,
      };
      case REVIEW_PRINT:
      return {
        ...state,
      }
    default:
      return state;
  }
};
