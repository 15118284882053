import styled from "styled-components";

export const SearchCardWrapper = styled.div`
  .container {
    color: gray;
    cursor: pointer; 
    display: grid;
    grid-template-columns: auto 1fr;
  }
  
  .picture {
    position: relative;
    width: 90px;
    height: 90px;
    overflow: hidden;
    margin: 0 12px;
    img {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      width: 100%;  
      // height: 15vw;
      display: flex !important;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      //clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 0 100%, 0% 50%, 0 1%);
    }

    .post-badge {
      position: absolute;
      top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-items: flex-end;

      &-left {
        left: 0;

        &-item {
          background-color: rgb(255, 182, 71);
          text-transform: uppercase;
          color: black;
          font-size: 11.5px;
          width: 70px;
          font-weight: bold;
          padding: 0 0.1rem 0.1rem 0.2rem;
          display: inline;
          clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);
        }
        &-red {
          background-color: #7fff00;
        }
        &-blue {
          background-color: #0019da;
        }
      }

      &-right {
        right: 0;

        &-item {
          background-color: #595959;
          text-transform: Uppercase;
          color: #ffffff;
          font-size: 12px;
          font-weight: bold;
          padding: 0 0.3rem 0.1rem 1rem;
          display: inline;
          clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0 100%, 10% 50%, 0% 1%);
          text-align: right;
        }
        &-red {
          background-color: #dc2626;
        }
        &-blue {
          background-color: #0019da;
        }
      }
    }

    .clip-path {
      position: absolute;
      padding: 0 2rem 0.1rem 1rem;
      color: white;
      top: 1rem;
      left: 0;
      background-color: #2cbf16;
      clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0 100%, 0% 50%, 0 1%);

      &-r {
        padding: 0 1rem 0.1rem 1rem;
        background-color: #da0022;
        left: auto;
        right: 0;
        clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0 100%, 10% 50%, 0% 1%);
      }

      &-b {
        padding: 0 1rem 0.1rem 1rem;
        background-color: #0019da;
        left: auto;
        right: 0;
        clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0 100%, 10% 50%, 0% 1%);
      }
    }
    .clip-draft {
      position: absolute;
      padding: 0 2rem 0.1rem 1rem;
      text-transform: uppercase;
      color: black;
      font-size: 11.5px;
      width: 70px;
      font-weight: bold;
      top: 2.7rem;
      left: 0;
      background-color: #ffb100;
      clip-path: polygon(100% 0%, 87% 50%, 100% 100%, 0 100%, 0% 50%, 0 1%);
    }
    .clip-drafts {
      position: absolute;
      padding: 0 2rem 0.1rem 1rem;
      color: black;
      text-transform: uppercase;
      font-size: 11.5px;
      width: 70px;
      font-weight: bold;
      top: 1rem;
      left: 0;
      background-color: #ffb100;
      clip-path: polygon(100% 0%, 87% 50%, 100% 100%, 0 100%, 0% 50%, 0 1%);
    }
  }

  .details {
    //padding: 0.8rem;
    // background-color: white;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    // border-left: 1px solid rgba(0, 0, 0, 0.2);
    // border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    font-size: 0.7rem;
   // height: 195px !important;
    .id {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 88%;
    }
    .title {
      //margin-top: 0.2rem;
      font-weight: bold;
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 85%;
    }

    .subtitlewrapper {
      display: flex;
      justify-content: space-between;

      .subtitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;
      }

      img {
        margin-right: 0.5rem;
      }
    }

    .pricewrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5rem;

      .price-value {
        color: #d50012;
      }
    }
    .quantitywrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 0.1rem;
    }
    .availabilitywrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 0.1rem;
      .availability-value {
        font-size: 10px;
      }
    }
  }
`;
