import {
  ALL_BUYER_REQUEST,
  CREATE_BUYER_REQUEST,
  DELETE_BUYER_REQUEST,
  MY_BUYER_REQUEST,
  SINGLE_BUYER_REQUEST,
} from "../ActionTypes";

const initialState = {
  // My Buyer Request
  myRequest: {
    entries: [],
    count: null,
    loading: false,
  },

  // Other Buyer Request
  allRequest: {
    entries: [],
    count: null,
    loading: false,
  },

  singleRequest: null,
};

/**
 *
 * @param { typeof initialState } state
 * @param {{ type: string, payload: any }} action
 */
export const BuyerRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case MY_BUYER_REQUEST: {
      return {
        ...state,
        myRequest: {
          ...state.myRequest,
          ...action.payload,
        },
      };
    }
    case ALL_BUYER_REQUEST: {
      return {
        ...state,
        allRequest: {
          ...state.allRequest,
          ...action.payload,
        },
      };
    }
    case CREATE_BUYER_REQUEST: {
      return {
        ...state,
        myRequest: {
          entries: [action.payload, ...state.myRequest.entries],
          count: state.myRequest.count + 1,
        },
      };
    }

    case SINGLE_BUYER_REQUEST: {
      return {
        ...state,
        singleRequest: action.payload,
      };
    }

    case DELETE_BUYER_REQUEST: {
      const requests = state.myRequest.entries.filter((r) => r._id !== action.payload);

      return {
        ...state,
        myRequest: {
          ...state.myRequest,
          entries: requests,
          count: state.myRequest.count - 1,
        },
      };
    }

    default: {
      return state;
    }
  }
};
