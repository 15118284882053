import {
  GET_PERFORMANCE_DETAIL,
  PERFORMANCE_LOADING,
  PERFORMANCE_LOADING_CANCEL,
  PERFORMANCE_PDF,
  PERFORMANCE_SEARCH,
  PERFORMANCE_SEARCH2
} from "../ActionTypes";

const intialState = {
  performanceData: [],
  performance2Data: {},
  performanceDetail: [],
  data: false,
  loader: false,
  loading: true,
};
export const PerformanceReducer = (state = intialState, action) => {
  switch (action.type) {
    case PERFORMANCE_LOADING:
      return {
        ...state,
        loader: true,
      };
    case PERFORMANCE_LOADING_CANCEL:
      return {
        ...state,
        loader: false,
      };
    case PERFORMANCE_SEARCH:
      return {
        ...state,
        performanceData: action.payload,
        data: true,
        loader: false,
      };
    case PERFORMANCE_SEARCH2:
      return {
        ...state,
        performance2Data: action.payload,
        data: true,
        loader: false,
      };
    case GET_PERFORMANCE_DETAIL:
      return {
        ...state,
        performanceDetail: action.payload.performanceDetail,
        loader: false,
      };
    case PERFORMANCE_PDF:
      return {
        ...state,
        // performanceData: action.payload,
        // data: true,
        // loader: false,
      };
    default:
      return state;
  }
};
