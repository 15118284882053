import React from "react";
import { Redirect, Route, Router, Switch, withRouter } from "react-router-dom";

import Loader from "./components/LoaderComp/LoaderComp";
import ScrollToTop from "./components/ScrollTop/ScrollTop";
import { AuthRoute, GuestRoute, history, PrivateRoute } from "./routes";

const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const MyDraft = React.lazy(() => import("./pages/MyDraft/MyDraft"));
const HelpDesk = React.lazy(() => import("./pages/HelpDesk/HelpDesk"));
const ProfileEdit = React.lazy(() => import("./pages/ProfileEdit/ProfileEdit"));
const NotificationPage = React.lazy(() => import("./pages/NotificationPage/NotificationPage"));
const NotFound = React.lazy(() => import("./pages/NotFoundPage/NotFound"));
const CancelOrder = React.lazy(() => import("./pages/CancelOrder/CancelOrder"));
const SearchPage = React.lazy(() => import("./pages/SearchPage/SearchPage"));
const InboxPage = React.lazy(() => import("./pages/InboxPage/InboxPage"));
const PerformanceChart = React.lazy(() => import("./pages/PerformanceChart/PerformanceChart"));
const PerformanceDetail = React.lazy(() => import("./pages/PerformanceDetail/PerformanceDetail"));
const PerformancePage = React.lazy(() => import("./pages/PerformancePage/PerformancePage"));
const ReviewPage = React.lazy(() => import("./pages/ReviewsPage/ReviewsPage"));
const CreateReview = React.lazy(() => import("./pages/CreateReview/CreateReview"));
const AcceptCancellation = React.lazy(() => import("./pages/AcceptCancellation/AcceptCancellation"));
const CancellationForm = React.lazy(() => import("./components/CancellationForm/CancellationForm"));
const Disputes = React.lazy(() => import("./pages/Disputes/Disputes"));
const MyPosts = React.lazy(() => import("./pages/MyPosts/MyPosts"));
const Cockpit = React.lazy(() => import("./pages/Cockpit/Cockpit"));
const PostPreview = React.lazy(() => import("./pages/PostPreview/PostPreview"));
const SignUpPage = React.lazy(() => import("./pages/SignUpPage/SignUpPage"));
const FreelancerPopUp = React.lazy(() => import("./pages/FreelancerPopup/FreelancerPopup"));
const VerifyEmailPage = React.lazy(() => import("./pages/VerifyEmailPage/VerifyEmailPage"));
const ForgetSuccessPage = React.lazy(() => import("./pages/ForgetSuccessPage/ForgetSuccessPage"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword/ResetPassword"));
const ChangePassword = React.lazy(() => import("./pages/ChangePassword/ChangePassword"));
const ForgetMailSend = React.lazy(() => import("./pages/ForgotMailSend/ForgotMailSend"));
const ForgetPage = React.lazy(() => import("./pages/ForgetPage/ForgetPage"));
const ServiceDetail = React.lazy(() => import("./pages/ServiceDetail/ServiceDetail"));
const CreatePost = React.lazy(() => import("./pages/CreatePost/CreatePost"));
const EditPost = React.lazy(() => import("./pages/EditPost/EditPost"));
const PostProList = React.lazy(() => import("./pages/PostProList/PostProList"));
const BuyerRequest = React.lazy(() => import("./pages/BuyerRequest/BuyerRequest"));
const MyRequests = React.lazy(() => import("./pages/BuyerRequest/MyRequests"));
const CreateRequest = React.lazy(() => import("./pages/BuyerRequest/CreateRequest"));
const ViewRequest = React.lazy(() => import("./pages/BuyerRequest/ViewRequest"));
const LoginPage = React.lazy(() => import("./pages/LoginPage/LoginPage"));
const TermsCondition = React.lazy(() => import("./pages/TermsCondition/TermsCondition"));
const ThankYou = React.lazy(() => import("./pages/ThankYou/ThankYou"));
const ServiceList = React.lazy(() => import("./pages/ServiceList/ServiceList"));
const LandingPage = React.lazy(() => import("./pages/LandingPage/LandingPage"));
const FlexPlans = React.lazy(() => import("./pages/FlexPlans/FlexPlans"));
const BookService = React.lazy(() => import("./pages/BookService/BookService"));
const GetForm = React.lazy(() => import("./components/GetForm/GetForm"));
const Following = React.lazy(() => import("./pages/Following/Following"));
const PaymentPage = React.lazy(() => import("./pages/Payment/PaymentPage"));
const BuyerFormPage = React.lazy(() => import("./pages/BuyerFormPage/BuyerFormPage"));
const SingpassJWKS = React.lazy(() => import("./pages/SingpassJWKS"));
const SingpassLogin = React.lazy(() => import("./pages/SingpassLogin"));
const PostProCategoryList = React.lazy(() => import("./pages/PostProCategoryList/PostProList"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const OrderDetail = React.lazy(() => import("./pages/OrderDetailPage/OrderDetail"));
const CreateAddress = React.lazy(() => import("./pages/CreateAddress/CreateAddress"));
const AddressList = React.lazy(() => import("./pages/AddressList/AddressList"));
const EditAddress = React.lazy(() => import("./pages/EditAddress/EditAddress"));
const StripeOnboardingReturnPage = React.lazy(() => import("./pages/StripeOnboardingProcess/StripeOnboardingReturnPage"));
const StripeOnboardingRefreshPage = React.lazy(() => import("./pages/StripeOnboardingProcess/StripeOnboardingRefreshPage"));
const MyFinancesPage = React.lazy(() => import("./pages/Finances/MyFinances"));

function AppRouter() {
  return (
    <React.Suspense fallback={<Loader />}>
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          {/* All Public Routes */}
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/reset/:slug?" component={ResetPassword} />
          {/* <Route exact path="/search" component={SearchPage} /> */}
          <Route exact path="/flex-plan" component={FlexPlans} />
          <Route exact path="/payment" component={PaymentPage} />
          <Route exact path="/thank-you" component={ThankYou} />
          <Route exact path="/buyer-webform" component={BuyerFormPage} />

          <Route exact path="/singpass-jwks" component={SingpassJWKS} />
          <Route exact path="/singpass-login" component={SingpassLogin} />

          {/* All Auth Routes */}
          <AuthRoute exact path="/login" component={withRouter(LoginPage)} />
          <AuthRoute exact path="/forget" component={ForgetPage} />
          <AuthRoute exact path="/signup/:slug?" component={SignUpPage} />

          {/* PRIVATE BASE PAGE */}
          <GuestRoute exact path="/book-service/:slug" component={BookService} />
          <PrivateRoute exact path="/terms" component={TermsCondition} />
          <GuestRoute exact path="/home" component={Dashboard} />
          <PrivateRoute exact path="/service-list" component={ServiceList} />

          <GuestRoute exact path="/buyer-forum" component={BuyerRequest} />
          <Redirect exact from="/postpro-list" to="/postpro-list/all" />
          <GuestRoute exact path="/postpro-list/:postType" component={PostProList} />
          <GuestRoute exact path="/postpro-list/category/:postType" component={PostProCategoryList} />
          

          <Redirect exact from="/buyer-request" to="/buyer-request/marketplace" />
          <PrivateRoute exact path="/buyer-request/create" component={CreateRequest} />
          <PrivateRoute exact path="/buyer-request/my-request" component={MyRequests} />
          <PrivateRoute exact path="/buyer-request/:id" component={ViewRequest} />
          <PrivateRoute exact path="/buyer-request/edit/:id" component={CreateRequest} />

          <PrivateRoute exact path="/orders/:status/:type" component={Cockpit} />
          <PrivateRoute exact path="/following" component={Following} />
          <PrivateRoute exact path="/mypost" component={MyPosts} />
          <PrivateRoute exact path="/mydraft" component={MyDraft} />
          <PrivateRoute exact path="/disputes" component={Disputes} />
          <PrivateRoute exact path="/create-post" component={CreatePost} />
          <PrivateRoute exact path="/edit-post/:id" component={EditPost} />
          <PrivateRoute exact path="/service-detail" component={ServiceDetail} />
          <PrivateRoute exact path="/forget-mail" component={ForgetMailSend} />
          <PrivateRoute exact path="/forget-success" component={ForgetSuccessPage} />
          <PrivateRoute exact path="/verify-email" component={VerifyEmailPage} />
          <PrivateRoute exact path="/pop-up" component={FreelancerPopUp} />
          <PrivateRoute exact path="/preview-post" component={PostPreview} />
          <PrivateRoute exact path="/getform" component={GetForm} />
          <PrivateRoute exact path="/cancel" component={CancellationForm} />
          <PrivateRoute exact path="/cancel-order" component={CancelOrder} />
          <PrivateRoute exact path="/accept-cancellation" component={AcceptCancellation} />
          <PrivateRoute exact path="/review/:orderId" component={CreateReview} />
          <PrivateRoute exact path="/order-view/:orderId" component={OrderDetail} />
          <PrivateRoute exact path="/reviews" component={ReviewPage} />
          <PrivateRoute exact path="/performance" component={PerformancePage} />
          <PrivateRoute exact path="/performance-details" component={PerformanceDetail} />
          <PrivateRoute exact path="/performance-chart" component={PerformanceChart} />
          <PrivateRoute exact path="/inbox" component={InboxPage} />
          <PrivateRoute exact path="/notification" component={NotificationPage} />
          <PrivateRoute exact path="/profile-edit" component={ProfileEdit} />
          <PrivateRoute exact path="/change-password" component={ChangePassword} />
          <PrivateRoute exact path="/help-desk" component={HelpDesk} />
          <PrivateRoute exact path="/search" component={SearchPage} />
          <PrivateRoute exact path="/create-address" component={CreateAddress} />
          <PrivateRoute exact path="/edit-address/:id" component={EditAddress} />
          <PrivateRoute exact path="/address" component={AddressList} />
          <PrivateRoute exact path="/onboarding-success" component={StripeOnboardingReturnPage} />
          <PrivateRoute exact path="/onboarding-failed" component={StripeOnboardingRefreshPage} />
          <PrivateRoute exact path="/finances" component={MyFinancesPage} />

          <GuestRoute exact path="/privacypolicy" component={PrivacyPolicy} />
          <GuestRoute exact path="/termscondition" component={TermsCondition} />
          {/* Default Routes 404 - Not Found */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </React.Suspense>
  );
}

export default AppRouter;
