import {
  CANCEL_ENGAGE_FORM,
  CANCEL_TOUCHIN_FORM,
  GET_ENGAGE_FORM,
  GET_TOUCHIN_FORM,
} from "../ActionTypes";

const intialState = {
  getintouchformclicked: false,
  getengageclicked: false,
};
export const FormReducer = (state = intialState, action) => {
  switch (action.type) {
    case GET_TOUCHIN_FORM:
      return {
        ...state,
        getintouchformclicked: true,
      };
    case CANCEL_TOUCHIN_FORM:
      return {
        ...state,
        getintouchformclicked: false,
      };
    case GET_ENGAGE_FORM:
      return {
        ...state,
        getengageclicked: true,
      };
    case CANCEL_ENGAGE_FORM:
      return {
        ...state,
        getengageclicked: false,
      };
    default:
      return state;
  }
};
