import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";

import store from "./redux/store";
import { persistor } from "./redux/store";
import { axiosMiddleware } from "./utils/Instance";
import AppRouter from "./AppRouter";
import GlobalStyle from "./GlobalStyle";
import reportWebVitals from "./reportWebVitals";

import "./styles/index.css";
import "react-toastify/dist/ReactToastify.css";

/**
 * window.Buffer defined here for file upload.
 * ReactS3 Upload Doesn't work in new version.
 * It required this line of code
 * for details https://stackoverflow.com/questions/67804053/uncaught-referenceerror-buffer-is-not-defined-in-react
 */
window.Buffer = window.Buffer || require("buffer").Buffer;

axiosMiddleware();

const Main = () => (
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GlobalStyle>
          <AppRouter />
        </GlobalStyle>
        <ToastContainer autoClose={2000} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(<Main />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
