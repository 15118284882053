import styled from "styled-components";

export const Input = styled.input`
  &[disabled] {
    opacity: 0.4;
    background-color: #ddd;
  }
  border: ${(props) => (props.className === "error" ? "1px solid red" : "1px solid #9A9A9A")};
  display: block;
  position: relative;
  height: ${(props) => props.height || "30px"};
  background: white url(${(props) => props.icon}) no-repeat;
  background-color: ${(props) => props.bg || "white"};
  background-position: left 5% bottom 50%;
  background-origin: padding-box;
  border-radius: ${(props) => props.borderad || "0.3rem"};
  border-top-left-radius: ${(props) => props.borderleft || "none"};
  width: ${(props) => props.width || "100%"};
  padding: ${(props) => props.padding || "0 0 0 2.6rem"};
  //padding:${(props) => props.icon && "0 0 0 1.4rem"};

  border-top-right-radius: ${(props) => props.tr || "none"};
  border-bottom-right-radius: ${(props) => props.br || "none"};
  border-top-left-radius: ${(props) => props.tl || "none"};
  border-bottom-left-radius: ${(props) => props.bl || "none"};
  display: ${(props) => props.display || ""};

  &:read-only {
    background-color: ${(props) => props.bg || "#f4f4f4"};
    outline: none;
    box-shadow: none;
    border: 0.5px solid #bbb;
    &:focus {
      outline: none;
      box-shadow: none;
      border: 0.5px solid #bbb;
    }
  }

  &:focus {
    outline: none;
    border: ${(props) => (props.errors ? "1px solid red" : "1px solid grey")};
    box-shadow: ${(props) => (props.errors ? "0 0 5px red" : "0 0 5px 0px #719ece")};
  }
`;

export const Textarea = styled.textarea`
  &[disabled] {
    opacity: 0.4;
    background-color: #ddd;
  }
  border: ${(props) => (props.className === "error" ? "1px solid red" : "1px solid #9A9A9A")};
  display: block;
  position: relative;
  background: white url(${(props) => props.icon}) no-repeat;
  background-color: ${(props) => props.bg || "white"};
  background-position: left 5% bottom 50%;
  background-origin: padding-box;
  border-radius: ${(props) => props.borderad || "0.3rem"};
  border-top-left-radius: ${(props) => props.borderleft || "none"};
  width: ${(props) => props.width || "100%"};
  padding: ${(props) => props.padding || "0 0 0 2.6rem"};
  //padding:${(props) => props.icon && "0 0 0 1.4rem"};

  border-top-right-radius: ${(props) => props.tr || "none"};
  border-bottom-right-radius: ${(props) => props.br || "none"};
  border-top-left-radius: ${(props) => props.tl || "none"};
  border-bottom-left-radius: ${(props) => props.bl || "none"};
  display: ${(props) => props.display || ""};

  &:read-only {
    /* background-color: #f4f4f4; */
    outline: none;
    box-shadow: none;
    border: 0.5px solid #bbb;
    &:focus {
      outline: none;
      box-shadow: none;
      border: 0.5px solid #bbb;
    }
  }

  &:focus {
    outline: none;
    border: ${(props) => (props.errors ? "1px solid red" : "1px solid grey")};
    box-shadow: ${(props) => (props.errors ? "0 0 5px red" : "0 0 10px #719ece")};
  }
`;
