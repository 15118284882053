import styled, { css } from "styled-components/macro";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.props.bg || "#DA0022"};
  color: ${(props) => props.props.color || "white"};
  height: ${(props) => props.props.height || "50px"};
  border-radius: ${(props) => props.props.borderrad || "0.5rem"};
  font-weight: ${(props) => props.props.fontweight || "bold"};
  opacity: ${(props) => props.disabled ? 0.5 : 1};

  // font-size: ${(props) => props.props.fontsize || "1rem"};
  border: ${(props) => props.props.border || "none"};
  margin: ${(props) => props.props.margin || "0 auto"};
  outline: none;
  cursor: pointer;
  ${(props) =>
    props.props?.className?.includes("w-")
      ? css``
      : css`
          width: ${(props) => props.props.width || "200px"};
        `}
    
  img {
    //margin-left:5px;
    margin: ${(props) => props.props.imargin || 0};
    width: ${(props) => props.props.iwidth || "22px"};
    height: ${(props) => props.props.iheight || "22px"};
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
