import {
  ADDRESS_LOADING,
  ADDRESS_LOADING_CANCEL,
  CREATE_ADDRESS,
  DELETE_ADDRESS,
  GET_ADDRESS,
  GET_ADDRESS_DETAIL,
  UPDATE_ADDRESS} from "../ActionTypes";

const intialState = {
  addresses: [],
  addressDetails: {},
  defaultAddress: [],
  loading: false,
};
export const AddressReducer = (state = intialState, action) => {
  switch (action.type) {
    case ADDRESS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADDRESS_LOADING_CANCEL:
      return {
        ...state,
        loading: false,
      };
    case GET_ADDRESS:
      const getDefaulAddress = action?.payload.filter( item => item.isPrimaryAddress );
      const getAddress = action?.payload.filter( item => !item.isPrimaryAddress )
      return {
        ...state,
        addresses: getAddress,
        defaultAddress: getDefaulAddress && getDefaulAddress[0],
        loading: false,
      };
    case GET_ADDRESS_DETAIL:
      return {
        ...state,
        addressDetails: action.payload?.data,
        loading: false,
      };
    case CREATE_ADDRESS:
      return {
        ...state,
        addresses: action.payload,
        loading: false,
      };
    case UPDATE_ADDRESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ADDRESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
