export const OrderStatus = {
  PROCESSING: "Processing",
  ENROUTE: "Enroute",
  ONGOING_NEGOTIATIONS: "Ongoing Negotiations",
  REJECTED_NEGOTIATIONS: "Rejected Negotiations",
  PENDING_PAYMENT: "Pending Payment",
  PENDING_ACCEPTANCE: "Pending Acceptance",
  DELIVERED: "Delivered",
  AGREED: "Agreed",
  ACCEPTED: "Accept",
  DECLINE: "Decline",
  DISPUTE: "Dispute",
  REVIEW: "Review",
  COMPLETE: "Complete",
  ACCEPT_DISPUTE: "Accept Dispute",
  PAYOUT_COMPLETE: "Payout Completed",
  UNSUCCESSFUL: "Unsuccessful",
};

export const _OrderStatus = {
  PAYMENT_PENDING: "PAYMENT_PENDING",
  ORDER_CANCELLED: "ORDER_CANCELLED",
  ORDER_REJECTED: "ORDER_REJECTED",
  PENDING_SELLER_CONFIRMATION: "PENDING_SELLER_CONFIRMATION",
  ORDER_COLLECTED: "ORDER_COLLECTED",
  ORDER_DELIVERED: "ORDER_DELIVERED",
  ORDER_DISPATCHED: "ORDER_DISPATCHED",
  READY_TO_COLLECT: "READY_TO_COLLECT",
  DELIVERY_IN_PROGRESS: "DELIVERY_IN_PROGRESS",
  RETURN_REJECTED: "RETURN_REJECTED",
  RETURN_ACCEPTED: "RETURN_ACCEPTED",
  RETURN_INITIATED: "RETURN_INITIATED",
  RETURN_COLLECTED: "RETURN_COLLECTED",
  REFUND_INITIATED: "REFUND_INITIATED",
  REFUND_COMPLETED: "REFUND_COMPLETED",
  ORDER_COMPLETED: "ORDER_COMPLETED",
  ONGOING_NEGOTIATIONS: 'Ongoing Negotiations',
  REJECTED_NEGOTIATIONS: 'Rejected Negotiations',
}

export const _PaymentStatus = {
  PAYMENT_PENDING: "PAYMENT_PENDING",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  PAYMENT_INITIATED: 'PAYMENT_INITIATED',
  PAYMENT_SUCCESSFUL: "PAYMENT_SUCCESSFUL",
  REFUND_INITIATED: "REFUND_INITIATED",
  REFUND_COMPLETED: "REFUND_COMPLETED",
  REFUND_UNDER_REVIEW: "REFUND_UNDER_REVIEW"
}

export const NotificationStatus = {
  Agreed_Offer: "Agreed Offer",
  Send_Offer: "Send Offer",
  Follow: "Follow",
  Unfollow: "Unfollow",
  Normal_Order: "Normal Order",
  Expire_Post: "Expire Post",
  Engaged_Post: "Engaged Post",
  Create_Order: "Create Order",
  Remove_Order: "Remove Order",
  Post_Taken_Off: "Post Taken Off",
  Payment_Success: "Payment Success",
  Payment_Fail: "Payment Fail",
  Message_Recived: "Message Received",
  Create_Post: 'Create Post',
};

export const MessageCount = {
  SYSTEM_MESSAGE_COUNT: "SYSTEM_MESSAGE_COUNT",
  NORMAL_MESSAGE_COUNT: "NORMAL_MESSAGE_COUNT",
};

export const SingpassKeys = {
  SINGPASS_URL: process.env.REACT_APP_SINGPASS_BASE_URL,
  CLIENT_ID: process.env.REACT_APP_SINGPASS_CLIENT_ID,
  REDIRECT_URI: process.env.REACT_APP_SINGPASS_REDIRECT_URI,
};
