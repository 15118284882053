import React, { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import ButtonComp from "../../components/Button/ButtonComp";
import LoaderComp from "../../components/LoaderComp/LoaderComp";
import ModalComp from "../../components/ModalComp/ModalComp";
import { getConversation } from "../../redux/actions/ChatAction";
import {
  deleteNotification,
  markAllNotificationAsSeen,
  markSeenNotification,
} from "../../redux/actions/NotificationAction";
import { createPayment } from "../../redux/actions/PaymentAction";
import { NotificationStatus } from "../../utils/constants";
import { formatDateTime } from "../../utils/mixin";

import { NotificationPageWrapper } from "./Notificationpagestyle";

export default function NotificationPage() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { notifications, hasMore, loading, unseen } = useSelector((state) => state.notification);
  const paymentLoading = useSelector((state) => state.payment.loading);

  const [paymentModal, setPaymentModal] = useState();
  const [openedNotification, setOpenedNotification] = useState(false);
  const [respondLoading, setRespondLoading] = useState();

  const handlePaymentClose = () => {
    setPaymentModal(false);
  };

  const handleAction = (nid) => {
    setOpenedNotification(openedNotification === nid ? null : nid);
  };

  return (
    <NotificationPageWrapper>
      <div className="notification">
        <div className="notification-head flex justify-between items-center">
          <div className="title">Notifications</div>
          {unseen > 0 && (
            <button
              onClick={() => {
                dispatch(markAllNotificationAsSeen());
              }}
              className="text-red-600 underline"
            >
              Mark all as seen
            </button>
          )}
        </div>
        <div>
          <div className="notification-data">
            {!loading && notifications.length === 0 && "No Notification"}
            {notifications &&
              notifications.map((notification, index) => (   
                <div className="notification-box" key={notification._id}>
                  <div className="notification-wrap">
                    <span className="notification-dot"></span>
                    <div className="notification-img w-16 h-16 sm:w-12 sm:h-12">
                      <img
                        src={notification?.sender_id?.profile || "/images/LogoRed.png"}
                        alt="NotificationPage"
                        onError={(e) => (e.target.src = "/images/LogoRed.png")}
                      />
                    </div>
                    <div className="notification-detail">
                      {/* <div>{notification?.message}</div> */}
                      <div>{notification?.subMessage || notification?.message}</div>
                      <div>
                        <b>{formatDateTime(notification?.createdAt)}</b>
                      </div>
                    </div>
                    <BsThreeDotsVertical
                      id={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleAction(notification._id);
                        // setDotBtn((dotBtn) => !dotBtn);
                        // console.log(index, no);
                      }}
                    />
                    {notification?.isSeen === false && <div className="new-tag">New</div>}
                  </div>
                  {/* {console.log(no === index)} */}
                  {openedNotification === notification._id && (
                    <div className="notification-btns">
                      {notification?.type === NotificationStatus.Agreed_Offer && (
                        <ButtonComp
                        name="Respond"
                        width="5rem"
                        height="30px"
                        fontsize=".9rem"
                        fontweight="500"
                        margin="0px"
                        loading={respondLoading}
                        onClick={() => {
                          setRespondLoading(true);
                          dispatch(
                            getConversation(
                              {
                                chat_id: notification.chat_id,
                                order_id: notification.order_id,
                              },
                              (success, error) => {
                                setRespondLoading(false);
                                if (error) {
                                  toast.error(error.message);
                                } else if (success) {
                                  dispatch(markSeenNotification(notification?._id));
                                  history.push({
                                    pathname: `/inbox`,
                                    state: { post_id: notification.post_id },
                                  });
                                }
                              }
                            )
                          );
                        }}
                      />
                      )}

                      {(notification?.type === NotificationStatus.Message_Recived ||
                        notification?.type === NotificationStatus.Send_Offer ||
                        notification?.type === NotificationStatus.Create_Order) && (
                        <ButtonComp
                          name="Respond"
                          width="5rem"
                          height="30px"
                          fontsize=".9rem"
                          fontweight="500"
                          margin="0px"
                          loading={respondLoading}
                          onClick={() => {
                            setRespondLoading(true);
                            dispatch(
                              getConversation(
                                {
                                  chat_id: notification.chat_id,
                                  order_id: notification.order_id,
                                },
                                (success, error) => {
                                  setRespondLoading(false);
                                  if (error) {
                                    toast.error(error.message);
                                  } else if (success) {
                                    dispatch(markSeenNotification(notification?._id));
                                    history.push({
                                      pathname: `/inbox`,
                                      state: { post_id: notification.post_id },
                                    });
                                  }
                                }
                              )
                            );
                          }}
                        />
                      )}
                      {notification?.type === NotificationStatus.Create_Post && (
                        <ButtonComp
                          className="px-3"
                          name="View Post"
                          width="auto"
                          fontsize=".9rem"
                          height="30px"
                          fontweight="500"
                          onClick={() => {
                            // return console.log(notification);
                            history.push({
                              pathname: `/book-service/${notification.post_id}`,
                              state: { detail: notification.post_id },
                            });
                          }}
                        />
                      )}
                      {notification?.isSeen === false && (
                        <ButtonComp
                          className="px-3"
                          name="Acknowledge"
                          width="auto"
                          fontsize=".9rem"
                          height="30px"
                          fontweight="500"
                          onClick={() => {
                            dispatch(markSeenNotification(notification?._id)).then(() => {
                              handleAction(notification._id);
                            });
                          }}
                          margin="0px"
                        />
                      )}

                      <ButtonComp
                        name="Delete"
                        // bg="gray"
                        width="4.5rem"
                        fontsize=".9rem"
                        height="30px"
                        fontweight="500"
                        onClick={() => {
                          dispatch(deleteNotification(notification?._id));
                        }}
                        margin="0px"
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>
          {/* Notification Loading */}
          {notifications.length !== 0 && hasMore && <LoaderComp className="h-24" />}
        </div>
      </div>

      <ModalComp modalVisible={paymentModal} modal={handlePaymentClose}>
        <div className="modal-com-name" style={{ marginBottom: "4rem" }}>
          Loading Payment Gateway API
        </div>
        <div className="modal-head">
          Your account details <br />
          have not been
        </div>
        <div style={{ fontSize: "2rem", marginBottom: "2rem" }}>DETECTED!</div>

        <div className="modal-id">#L1243373839</div>
        <div className="modal-com-name">Proceeding to Payment Gateway</div>

        {/* <div className="modal-btn">
          <ButtonComp
            name="NO"
            bg="black"
            width="100%"
            height="34px"
            fontsize=".7rem"
            //onClick={handleEnrouteClose}
          />
        </div> */}
      </ModalComp>
    </NotificationPageWrapper>
  );
}
