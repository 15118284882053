import styled from "styled-components";

export const SideBarWrapper = styled.div`
  .bar-wrap {
    display: flex;
    background: #FFEBEE;
    // width: 17vw;
    //border-top-right-radius: 3rem;
    //border-bottom-right-radius: 3rem;
    // margin: 1rem 0;
    //box-shadow: 0 0 9px 8px rgba(0,0,0,0.1);
    //box-shadow: 0px 0px 30px #ccc;

    .nav-wrap {
      /* width: 80%; */
      //height: 3.3rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: gray;
      margin: 0 1rem;
      //padding: 1rem 2rem 2rem .5rem;

      .icon {
        font-size: 1.4rem;
        padding: 0.08rem 0.6rem;
        margin: 0.3rem 0;
      }

      .right {
        margin-left: auto;
      }

      &:hover,
      svg:hover {
        color: #c50000;
      }
      h4 {
        font-weight: 400;
        margin: 1rem 0;
      }
    }
    .bar-btn {
      text-align: center;
      padding-top: 1rem;
      padding-bottom: 3rem;
    }
    .active {
      h4 {
        font-weight: bold;
      }
    }
  }
  @media only screen and (max-width: 667px) {
    .bar-wrap{white-space: nowrap; overflow-x: auto;}
  }
  .hidden {
    display: none;
  }
`;
