import React, { useEffect, useRef, useState } from "react";
import { isAndroid, isIOS } from "react-device-detect";
import { BsBell, BsBookmark, BsChevronDown, BsEnvelope, BsHeadphones, BsHouseDoor, BsPerson } from "react-icons/bs";
import { IoIosMenu } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import { isMobileDevice } from "react-select/dist/index-ea9e225d.cjs.prod";
import { debounce } from "lodash";

import NotificationPage from "../../pages/NotificationPage/NotificationPage";
import { checkSellerStatus } from "../../redux/actions/AuthActions";
import { getAllConversation, getHelpDeskConversation, updateHelpDeskChat } from "../../redux/actions/ChatAction";
import { addNotification, getNotification } from "../../redux/actions/NotificationAction";
import { getSearchPosts } from "../../redux/actions/PostAction";
import { LOGOUT_USER } from "../../redux/ActionTypes";
import { MessageCount } from "../../utils/constants";
import { chatUnreadCount, initSocket, subscribeNotification, subscribeToHelpDesk } from "../../utils/socket";
import ButtonComp from "../Button/ButtonComp";
import InputComp from "../Input/InputComp";
import SearchCardComp from "../SearchCard/SearchCardComp";

import { HeaderWrapper } from "./Headerstyles";

const { REACT_APP_ENVIRONMENT } = process.env;

function HeaderComp({ onClickBurger, ...props }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const notificationRef = useRef();
  const location = history.location;

  const { unseen, hasMore } = useSelector((state) => state.notification);
  const { user, token } = useSelector((state) => state.auth);
  const totalUnreadMsg = useSelector((state) => state.chat.totalUnread);
  const [search, setSearch] = useState("");
  const [dropdown, setDropdown] = useState(null); // enum -> notification | profile
  const [page, setPage] = useState(1);
  const [postPage, setPostPage] = useState(1);
  const [limit] = useState(50);

  const {
    searchPosts: { posts, totalPost },
    loading,
  } = useSelector((state) => state.post);

  const userId = user?._id;

  const handleLogout = () => {
    dispatch({
      type: LOGOUT_USER,
    });
    history.push("/home");
  };

  function getEnv() {
    return {
      isDev: REACT_APP_ENVIRONMENT === "development",
      isStage: REACT_APP_ENVIRONMENT === "staging",
      isProd: REACT_APP_ENVIRONMENT === "production",
    };
  }
  // Notification and User dropdown hide onclick outside
  useEffect(() => {
    window.onclick = (e) => {
      if (dropdown) {
        if (!notificationRef?.current?.contains(e.target)) {
          setDropdown(null);
        }
      }
      setSearch("");
    };
  });

  // Device Detect 

   useEffect(() => {
    if (isAndroid) {
      window.location.replace("https://play.google.com/store/apps/details?id=com.tuleasy.construcshare");
    } 
    
    if (isIOS) {
      window.location.replace("https://apps.apple.com/in/app/construcshare/id1615271485");
    }
  }, []);

 

  useEffect(() => {
    setDropdown(null);
    setSearch("");
  }, [location]);

  const debounceNotification = debounce(() => {
    dispatch(getNotification({ page, limit }));
  }, 1000);

  useEffect(() => {
    if (token) {
      debounceNotification();
    }
  }, [page, token]);

  useEffect(() => {
    if (dropdown === "notification") {
      debounceNotification();
    }
  }, [dropdown]);

  useEffect(() => {
    if (token) {
      dispatch(checkSellerStatus());
    }
  }, [token]);

  useEffect(() => {
    const client = notificationRef?.current;
    if (client) {
      client.onscroll = (e) => {
        if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 5) {
          if (hasMore) setPage(page + 1);
        }
      };
    }
  });

  useEffect(() => {
    if (userId) {
      initSocket(`USER_${String(user._id)}`);

      chatUnreadCount(() => {
        dispatch(getAllConversation());
      });

      subscribeNotification((data) => {
        if (data) {
          dispatch(addNotification(data));
        }
      });

      subscribeToHelpDesk((chat) => {
        dispatch(updateHelpDeskChat(chat));
      });

      dispatch(getAllConversation());
      dispatch(getHelpDeskConversation());
    }
  }, [userId]);
  
  const fetchPosts = () => dispatch(getSearchPosts({ type: null, limit: 20, page: postPage, search,  category: ""  }));

  useEffect(() => {
    fetchPosts();
  }, [postPage, search]);

  return (
    <HeaderWrapper props={props}>
      <div
        className={`container-head w-full header-container nav ${
          getEnv().isDev ? "bg-primary" : getEnv().isStage ? "bg-sky-600" : "bg-primary"
        }`}
      >
        <div className="flex justify-start logo-sec">
          
          <a className="logo-wrapper" href="https://construcshare.com">
            <img
              src="/icons/logo-white.svg"
              alt="logo"
              onClick={() => {
                history.push("/home");
              }}
            />

            <span className="title md:block">
              construc<b>share</b>
            </span>
          </a>
        </div>
        <div className={`${location.pathname === "/home" ? "show-search" : "hidden-search"} search-wrapper`}>
            <div className="input">
              <InputComp
                icon="/icons/search.svg"
                placeholder="Search post"
                height="45px"
                borderRadius="0.7rem"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                value={search}
                className="search-input"
              />
            </div>
            <div>
            {search && 
            <div className="search-response-sec w-24 lg:w-80 md:w-60 xs:w-25">
            {posts?.map((post) => (
            <div
              onClick={() => {
                history.push({
                  pathname: `/book-service/${post._id}`,
                  state: { detail: post, form: true },
                });
              }}
              key={post?._id}
            >
              <div className="service-detail">
                <SearchCardComp data={post} />
              </div>
            </div>
            ))}
            </div>
            }
            </div>
            
            </div>
           
        {props.mainheader ? (
          <div className="feature-wrapper flex">
            {/* <div className="input lg:w-80 md:w-60  hidden md:block" onClick={() => history.push("/search")}>
              <InputComp
                icon="/icons/search.svg"
                placeholder="Search transactions, invoices or help"
                // width="340px"
                height="35px"
                borderradius="0.5rem"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onFocus={() => history.push("/search")}
                autoFocus={props.autoFocus}
              />
            </div> */}

            <div
              className="header-icon flex gap-2 items-center"
              onClick={() => {
                history.push("/inbox");
              }}
            >
              <BsEnvelope />
              {Number(totalUnreadMsg) !== 0 && (
                <>
                  <span className="-mt-0.5">|</span>
                  <span>{totalUnreadMsg}</span>
                </>
              )}
            </div>

            <div
              className="header-icon flex gap-2 items-center"
              onClick={() => {
                // history.push("/notification");
                userId ? setDropdown((dropdown) => (dropdown === "notification" ? null : "notification")) : history.push("/notification")
              }}
            >
              <BsBell />
              {unseen > 0 && (
                <>
                  <span className="-mt-0.5">|</span>
                  <span>{unseen}</span>
                </>
              )}
            </div>

            <div className="vl hidden md:block"></div>
            {user ? 
            <div className="profile-sec">
            <div
              className="profile"
              onClick={() => {
                setDropdown((dropdown) => (dropdown === "profile" ? null : "profile"));
              }}
            >
              <img
                src={user?.profile ? user?.profile : "../images/avtar.png"}
                alt="profile"
                onError={(e) => (e.target.src = "/images/avtar.png")}
              />
              <div className="profile-info hidden md:block">
                <div className="profile-info-msg">Hello</div>
                <div className="profile-info-name">{user?.name || "****"} </div>
              </div>
            </div> 

            <div
              className="arrow-btn dropdown mt-2 sm:mt-0   z-20"
              onClick={() => {
                setDropdown((dropdown) => (dropdown === "profile" ? null : "profile"));
              }}
            >
              <BsChevronDown className="hidden md:block" />
              {dropdown === "profile" && (
                <div className="dropdown-content w-44">
                  <Link to="/profile-edit">Profile</Link>
                  <Link to="/address">Addresses</Link>
                  <Link to="/change-password">Change Password</Link>
                  <Link to="/help-desk">Help Desk</Link>
                  <h5 onClick={handleLogout}>Logout</h5>
                </div>
              )}
            </div>
            </div>: <div className="bell-icon flex gap-2 items-center"><Link to="/login">Login</Link></div> }
          </div>
        ) : (
          <div className="feature-buttons flex flex-row gap-8 sm:gap-4 items-center">
            {/* <div className="feature-link">How it Works ?</div> */}
            {/* <div className="feature-link sm:text-xs">Help Desk</div> */}
          </div>
        )}
      </div>

      <div className="bottom-menu md:hidden" style={{position: "fixed", background: "#fff", boxShadow: "0 0 6px rgba(0,0,0,0.2)", width: "100%", left: "0", bottom: "0", zIndex: "99999"}}>
          <ul style={{display: "grid",padding: "2px", gridTemplateColumns: "repeat(5, 1fr)", alignItems: "center", textAlign: "center"}}>
          <li>
          <NavLink
            to="/home"
            className={(isActive) =>
              (!dropdown && isActive ? "nav-active" : "nav-")
            }
          >
            
              <BsHouseDoor />
            </NavLink>
            </li>
            <li style={{padding: "15px", position: "relative"}} className={` ${dropdown === "notification" && "nav-active"}`}>
            <div
              className={`notify-icon items-center`}
              onClick={() => {
                userId ? setDropdown((dropdown) => (dropdown === "notification" ? null : "notification")) : history.push("/notification")
              }}
            >
              <BsBell color="#000" />
              {unseen > 0 && (
                <div className="count-wrap">
                  {/* <span className="-mt-0.5">|</span> */}
                  <span>{unseen >= 100 ? "99+" : unseen}</span>
                </div>
              )}
             
            </div>
            </li>
            <li style={{padding: "15px", position: "relative"}}>
            <NavLink
                activeStyle={{ color: "#dc2626" }}
                style={{ textDecoration: "none" }}
                to="/inbox"
                className={(isActive) =>
                  (!dropdown && isActive ? "nav-active" : "nav-")
                }
                onClick={() => {
                  history.push("/inbox");
                }}
              >
              <BsEnvelope color="#000" />
            </NavLink>
            {Number(totalUnreadMsg) !== 0 && (
                <div className="notifi-count-wraps">
                  {/* <span className="-mt-0.5">|</span> */}
                  <span>{totalUnreadMsg}</span>
                </div>
              )}
            </li>
            <li style={{padding: "15px"}}>
            <NavLink
                activeStyle={{ color: "#dc2626" }}
                style={{ textDecoration: "none" }}
                to="/help-desk"
                className={(isActive) =>
                  (!dropdown && isActive ? "nav-active" : "nav-")
                }
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/help-desk";
                }}
              >
              <BsHeadphones />
              </NavLink>
            </li>
            <li className={` ${dropdown === "profile" && "nav-active"}`} style={{padding: "15px"}} onClick={() => {
                userId ?
                setDropdown((dropdown) => (dropdown === "profile" ? null : "profile")) : history.push("/profile-edit");
              }}>
              <div >
                <BsPerson />
              </div>
            </li>
          </ul>
        </div>
        {dropdown === "notification" && (
              <div className="notify-wrapper top-20 right-14 h-2/3 lg:w-1/3 md:w-1/2 sm:w-full sm:right-0 sm:h-full ">
                <div className="w-5 h-5 bg-gray-100 absolute right-28 -top-2 border-t border-l rotate-45 hidden-mobile"></div>
                <div
                  id="notification-container"
                  ref={notificationRef}
                  className="absolute top-0 right-0 bg-gray-100 p-5 overflow-y-scroll h-full w-full border-b border-l border-r z-20 rounded-2xl shadow-2xl"
                >
                  <NotificationPage />
                </div>
              </div>
            )}

            {userId && dropdown === "profile" && (
                <div className="dropdown-mobile-content w-44">
                  <Link to="/profile-edit">Profile</Link>
                  <Link to="/change-password">Change Password</Link>
                  <h5 onClick={handleLogout}>Logout</h5>
                </div>
              )}
    </HeaderWrapper>
  );
}

export default HeaderComp;
