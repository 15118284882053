import React from "react";

import LoaderComp from "../components/LoaderComp/LoaderComp";

export const PrivatePageWrapper = ({ title, action, children, loading = false, footer = "", className, ...props }) => {
  return (
    <div {...props}>
      <div className="service-head flex items-center justify-between sm:flex-col sm:items-start">
        <div className="service-title">{title}</div>
        {action}
      </div>

      <div className={`relative mt-5 `}>
        <div className={`${className}`}>{children}</div>
        {loading && (
          <div
            className="absolute top-0 left-0  bg-white/[0.8] w-full h-full flex items-center justify-center"
            style={{ minHeight: 300 }}
          >
            <span>
              <LoaderComp className="h-32" />
            </span>
          </div>
        )}
      </div>
      {footer}
    </div>
  );
};
