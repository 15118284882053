import React from "react";
import Loader from "react-loader-spinner";

import { Button } from "./ButtonStyle";

function ButtonComp({ loading, ...props }) {
  return (
    <Button
      {...props}
      className={props.className}
      props={props}
      id={props.id}
      onClick={props.onClick}
      type={props.type || "button"}
      disabled={loading || props.disabled || false}
      ref={props.ref}
    >
      {props.first && props.icon && <img style={props.iconStyle} src={`/icons/${props.icon}`} alt="" />}
      {loading ? (
        <Loader type="TailSpin" color={props.lcolor || "#FFF"} height={30} width={30} />
      ) : props.htmlFor ? (
        <label htmlFor={props.htmlFor} className="sm:text-xs cursor-pointer w-full block">
          {props.name}
        </label>
      ) : (
        <span className="sm:text-xs">{props.name}</span>
      )}
      {props.second && props.icon && <img src={`/icons/${props.icon}`} alt="" />}
    </Button>
  );
}

export default ButtonComp;
